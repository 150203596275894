@mixin theme_impl($blue-1, $blue-2, $blue-3, $blue-4, $dark, $banner, $io-elon, $io-elon-before, $sidebar-toggler) {
    //Das hier macht die Werte auch in Javascript zugänglich
    //getComputedStyle(document.body).getPropertyValue("--blue-1");
    //Das -- am Anfang bedeutet, es ist ein CSS Variable
    --blue-1: #{$blue-1};
    --blue-2: #{$blue-2};
    --blue-3: #{$blue-3};
    --blue-4: #{$blue-4};
    --dark: #{$dark};
    --mat-datepicker-calendar-date-selected-state-background-color: #{$blue-4};
    --mat-datepicker-calendar-date-in-range-state-background-color: #{$blue-1};
    --mat-stepper-header-selected-state-icon-background-color: #{$blue-4};
    --mat-stepper-header-edit-state-icon-background-color: #{$blue-2};

    @each $key, $value in $banner {
        .banner {
            #{$key}: $value
        }
    }

    @each $key, $value in $io-elon {
        .io-elon {
            #{$key}: $value
        }
    }

    @each $key, $value in $io-elon-before {
        .io-elon::before {
            #{$key}: $value
        }
    }

    @each $key, $value in $sidebar-toggler {
        #sidebar-toggler-close {
            #{$key}: $value
        }
    }

    .c-blue-1 {
        color: $blue-1 !important
    }

    .bg-blue-1 {
        background-color: $blue-1 !important;
    }

    .c-blue-2 {
        color: $blue-2 !important
    }

    .bg-blue-2 {
        background-color: $blue-2 !important;
    }

    .c-blue-3 {
        color: $blue-3 !important
    }

    .bg-blue-3 {
        background-color: $blue-3 !important;
    }

    .c-blue-4 {
        color: $blue-4 !important
    }

    .bg-blue-4 {
        background-color: $blue-4 !important;
    }

    .c-dark {
        color: $dark !important;
    }

    .bg-dark {
        background-color: $dark !important;
    }

    .iwhite, .iwhite svg {
        color: white;
        fill: white;
        stroke: #ffffff;
    }

    .iblack, .iblack svg {
        color: #000000;
        fill: #000000;
        stroke: #000000;
    }

    .idef, .idef svg {
        color: #F7F7F7;
        fill: #F7F7F7;
        stroke: #F7F7F7;
    }

    .igray, .igray svg {
        color: #cccccc;
        fill: #cccccc;
        stroke: #cccccc;
    }

    .iblue, .iblue svg {
        color: $blue-1;
        fill: $blue-1;
        stroke:$blue-1;
    }
}
