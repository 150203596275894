// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "theme_impl";

.iod-theme {
    $blue-1: #5dc0e3;
    $blue-2: #5194cc;
    $blue-3: #31597a;
    $blue-4: #31597a;
    $dark: darkgray;
    $banner: (
        visibility: hidden,
        height: 0px,
        width: 0px
    );
    $io-elon: (
        color: #{$blue-1},
        text-decoration: none,
        font-size: 32px,
        font-weight: bold,
        float:left,
        margin-left: 15px,
        width: 120px,
        overflow: visible,
        white-space: nowrap
    );
    $io-elon-before: ();
    $sidebar-toggler: (
    );

    @include theme_impl($blue-1, $blue-2, $blue-3, $blue-4, $dark, $banner, $io-elon, $io-elon-before, $sidebar-toggler);
}

.amueller-theme {
    $blue-1: #f18600;
    $blue-2: #b46400; //Ist nur ein geratener Wert, wenn es offizielle Werte gibt sollte das hier ersetzt werden
    $blue-3: #784300; //Ist nur ein geratener Wert, wenn es offizielle Werte gibt sollte das hier ersetzt werden
    $blue-4: #3c2100; //Ist nur ein geratener Wert, wenn es offizielle Werte gibt sollte das hier ersetzt werden
    $dark: darkgray;



    $banner: (
        border: 10px white solid,
        border-top:12px white solid,
        border-bottom: 8px white solid,
        background: url("/assets/img/branding/amueller.jpg"),
        width: 180px,
        height: 44px,
        background-repeat: no-repeat,
        background-size: 180px 44px
    );
    $io-elon: (
        color: #{$blue-1},
        text-decoration: none,
        font-size: 1em,
        float:left,
        margin-left: 10px,
        margin-top: 5px
    );
    $io-elon-before: (
        color: white,
        content: "powered\00a0 by\00a0"
    );
    $sidebar-toggler: (
        float: right,
        position: absolute,
        top: 60px,
        left: 150px
    );

    @include theme_impl($blue-1, $blue-2, $blue-3, $blue-4, $dark, $banner, $io-elon, $io-elon-before, $sidebar-toggler);
}


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


$my-blue: (
    50: var(--blue-1),
    100: var(--blue-2),
    200: var(--blue-2),
    300: var(--blue-2),
    400: var(--blue-3),
    500: var(--blue-3),
    600: var(--blue-4),
    700: var(--blue-4),
    800: var(--blue-4),
    900: var(--blue-4),
    A100: var(--blue-1),
    A200: var(--blue-1),
    A400: var(--blue-1),
    A700: var(--blue-1),
    contrast: (
        50: black,
        100: black,
        200: black,
        300: black,
        400: black,
        500: black,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: black,
        A200: black,
        A400: black,
        A700: black,
    )
);

$my-blue-accent: (
    50: var(--blue-4),
    100: var(--blue-3),
    200: var(--blue-3),
    300: var(--blue-3),
    400: var(--blue-2),
    500: var(--blue-2),
    600: var(--blue-1),
    700: var(--blue-1),
    800: var(--blue-1),
    900: var(--blue-1),
    A100: var(--blue-4),
    A200: var(--blue-4),
    A400: var(--blue-4),
    A700: var(--blue-4),
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: black,
        700: black,
        800: black,
        900: black,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);

$theme-primary: mat.define-palette($my-blue-accent);
$theme-accent: mat.define-palette($my-blue);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme(
        (
            color: (
                primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn,
            ),
            typography: mat.define-typography-config(),
        )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

